import store from '@/store';
import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {container} from 'tsyringe';
import {CreateChannelRequest, CreateChannelUseCase} from '@/domain/createChannelUseCase'
import {Channel, ChannelsState} from '@/domain/model/types';
import {firestore, storage} from '@/plugins/firebase.init';
import {getDownloadURL, ref, uploadBytes} from 'firebase/storage'
import {
  addDoc,
  deleteDoc,
  GeoPoint,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  Timestamp,
  updateDoc,
  arrayRemove,
  arrayUnion,
  writeBatch,
  where
} from 'firebase/firestore'
import {applicationStore} from '@/store/modules/application';
import {profileStore} from '@/store/modules/profile';
import {EditChannelRequest, EditChannelUseCase} from '@/domain/editChannelUseCase';
import axios from '@/plugins/axios';
import {appConfig, dynamicLinkConfigCustomer} from '@/plugins/firebase.config';
import rfdc from 'rfdc';
import {
  businessChannel,
  businessChannelMember,
  businessChannelMembers,
  businessChannelMessage,
  businessChannelMessages,
  businessChannels,
  businessCustomers
} from '@/data/firebase';

const createChannel: CreateChannelUseCase = container.resolve<CreateChannelUseCase>('CreateChannel')
const editChannel: EditChannelUseCase = container.resolve<EditChannelUseCase>('EditChannel')
const clone = rfdc({proto: true})

const defaultState: ChannelsState = {
  bizChannels: [],
  loadingChannel: false,
  selectedChannel: null,
  selectedChannelMembers: null,
  nonSelectedChannelMembers: null,
  cachedChannelMessages: {},
  channelMessages: [],
  channelMessagesLoaded: false
}

const channelMessageType: any = {
  TEXT: 1,
  IMAGE: 2,
  LOCATION: 3,
  CONTACT: 4
}

@Module({name: 'channels-store', dynamic: true, store})
export default class ChannelsStore extends VuexModule {
  private _bizChannels: Channel[] = clone(defaultState).bizChannels
  private _loadingChannel: boolean = clone(defaultState).loadingChannel
  private _selectedChannel: Channel | null = clone(defaultState).selectedChannel
  private _selectedChannelMembers: any[] | null = clone(defaultState).selectedChannelMembers
  private _nonSelectedChannelMembers: any[] | null = clone(defaultState).nonSelectedChannelMembers
  private _cachedChannelMessages: any = clone(defaultState).cachedChannelMessages
  private _channelMessages: any[] = clone(defaultState).channelMessages
  private _channelMessagesLoaded: boolean = clone(defaultState).channelMessagesLoaded

  get channelMessages() {
    return this._channelMessages
  }

  get messagesLoaded() {
    return this._channelMessagesLoaded
  }

  get businessChannels() {
    return this._bizChannels
  }

  get loading() {
    return this._loadingChannel
  }

  get selectedChannel() {
    return this._selectedChannel
  }

  get channelsCount() {
    return this._bizChannels.length
  }

  get selectedChannelMembers() {
    return this._selectedChannelMembers
  }

  get nonSelectedChannelMembers() {
    return this._nonSelectedChannelMembers
  }

  @Mutation
  public setLoading(value: boolean) {
    this._loadingChannel = value;
  }

  @Mutation
  public putChannel(channel: Channel) {
    this._bizChannels.push(channel)
  }

  @Mutation
  public updateChannel(channel: Channel) {
    const modifiedIndex = this._bizChannels.findIndex((item) => !!item && item.id === channel.id);
    this._bizChannels.splice(modifiedIndex, 1, channel)
  }

  @Mutation
  public removeChannel(channel: Channel) {
    const removedIndex = this._bizChannels.findIndex((item) => !!item && item.id === channel.id);
    this._bizChannels.splice(removedIndex, 1)
  }

  @Mutation
  public selectChannel(channel: Channel | null) {
    this._selectedChannel = channel
  }

  @Mutation
  public selectChannelMembers(members: any[] | null) {
    this._selectedChannelMembers = members
  }

  @Mutation
  public nonSelectChannelMembers(nonMembers: any[] | null) {
    this._nonSelectedChannelMembers = nonMembers
  }

  @Action
  public async addChannel(data: { name, description, imageFile }) {
    const businessId = applicationStore.businessId;
    const associate = profileStore.t2bUser;
    if (!businessId || !associate.id) {
      return null
    }
    this.setLoading(true)
    const request: CreateChannelRequest = {
      associateId: associate.id,
      associateName: associate.fullName || '',
      businessId,
      businessName: applicationStore.businessName || '',
      description: data.description,
      imageFile: data.imageFile,
      name: data.name
    };
    const id = await createChannel(request)
    this.setLoading(false)
    return id
  }

  @Action
  public async editChannel(data: { name, description, imageFile }) {
    const businessId = applicationStore.businessId;
    const channelId = this.selectedChannel?.id;
    if (!businessId || !channelId) {
      return null
    }
    this.setLoading(true)
    const request: EditChannelRequest = {
      businessId,
      channelId,
      description: data.description,
      imageFile: data.imageFile,
      name: data.name
    };
    const id = await editChannel(request)
    this.setLoading(false)
    return id
  }

  @Action
  public async loadChannels() {
    const businessId = applicationStore.businessId
    if (!businessId) {
      return null
    }
    return onSnapshot(businessChannels(businessId),
      (snapshot) => {
        snapshot.docChanges().forEach((docChange) => {
          const channel = docChange.doc.data() as Channel;
          channel.id = docChange.doc.id;
          switch (docChange.type) {
            case 'added':
              this.putChannel(channel)
              break;
            case 'modified':
              this.updateChannel(channel)
              break;
            case 'removed':
              this.removeChannel(channel)
              break;
            default:
          }
        });
      });
  }

  @Action
  public async loadChannel(channelId: string) {
    if (!channelId) {
      return null
    }
    const businessId = applicationStore.businessId
    return onSnapshot(businessChannel(businessId!!, channelId),
      (channelSnapshot) => {
        if (channelSnapshot.exists()) {
          const channel = { ...channelSnapshot.data(), id: channelSnapshot.id } as Channel;
          this.selectChannel(channel)
        }
      })
  }

  @Action
  public async loadChannelMembers(channelId: string) {
    const businessId = applicationStore.businessId
    if (!businessId) {
      return null
    }

    if (!channelId) {
      return null
    }

    const querySnapshot = await getDocs(businessChannelMembers(businessId, channelId))
    const data = querySnapshot.docs.map((doc) => {
      return {id: doc.id, ...doc.data()};
    })
    this.selectChannelMembers(data)
    return data
  }

  @Action
  public async loadNonChannelMembers(channelId: string) {
    const businessId = applicationStore.businessId
    if (!businessId || !channelId) {
      return null
    }

    try{      
      let querySnapshot: any;

       //all
      querySnapshot = await getDocs(
        query(businessCustomers(businessId)
      ));      
        
      let data = querySnapshot.docs.map((doc) => {     
        return {
          id: doc.id, 
          name: doc.data().fullName || '',
          photoUrl: doc.data().photoUrl?.thumbnail || '',
          online: doc.data().status?.online || false,
          dob: doc.data().dob || '',
          allowedViewDOB: doc.data().permissions?.viewDOB || ''
        };
      })

      //filter
      if(this._selectedChannelMembers &&  this._selectedChannelMembers.length > 0){
        var selectedIds =  this._selectedChannelMembers?.map(item => item.id);

        if(selectedIds && selectedIds.length > 0){
          const set = new Set(selectedIds);
          data = data.filter(item => !set.has(item.id));
        }
      }

      this.nonSelectChannelMembers(data)      
      return data      
    } catch (err) {
      console.error('failed to loadNonChannelMembers')
      console.error(err)      
    }
    
    return false    
  }

  @Mutation
  private clearCachedMessagesForChannel(channelId: string) {
    delete this._cachedChannelMessages[channelId]
  }

  @Action
  public async deleteSelectedChannel() {
    if (!this.selectedChannel) {
      return false
    }
    const channelId = this.selectedChannel.id!!;
    this.clearCachedMessagesForChannel(channelId)
    const businessId = this.selectedChannel.business!.id!;
    try {
      await deleteDoc(businessChannel(businessId!, channelId))
      this.selectChannel(null)
      return true
    } catch (err) {
      console.error(err)
      return false
    }
  }

  @Action
  public async activateDisableSelectedChannel() {
    if (!this.selectedChannel) {
      return false
    }
    const disable = !this.selectedChannel.disabled

    const channelReference = businessChannel(this.selectedChannel.business!!.id!!, this.selectedChannel.id!!);
    try {
      await updateDoc(channelReference, 'disabled', disable)
      this.activateSelectedChannel(disable)
      return true
    } catch (err) {
      console.error(err)
      return false
    }
  }

  @Mutation
  private activateSelectedChannel(disable: boolean) {
    if (!this._selectedChannel) {
      return
    }
    this._selectedChannel.disabled = disable
  }

  @Action
  public async sendChannelTextMessage(message: { text,  isSMS}) {
    const channel = this.selectedChannel;
    const channelId = channel?.id;
    const businessId = channel?.business?.id;
    if (!channelId || !businessId) {
      return
    }
    const name = profileStore.t2bUser.fullName;
    const uid = profileStore.t2bUser.id;
    const data: any = {
      text: message.text,
      sender: {uid, name},
      type: channelMessageType.TEXT,
      photoUrl: channel?.imageUrl || null,
      isSMS: message.isSMS,
      platform: 'web',
      createdDate: Timestamp.now()
    };
    try {
      await addDoc(businessChannelMessages(businessId, channelId), data);
    } catch (e) {
      console.error('failed to send message to channel.', e)
    }
  }

  @Action
  public async editChannelTextMessage(message: { id, text }) {
    const channel = this.selectedChannel;
    const channelId = channel?.id;
    const businessId = channel?.business?.id;
    if (!channelId || !businessId) {
      return
    }
    try {
      await updateDoc(
        businessChannelMessage(businessId, channelId, message.id),
        'text', message.text,
        'edited', true,
        'updatedDate', Timestamp.now()
      );
    } catch (e) {
      console.error('failed to update message.', e)
    }
  }

  @Action
  public async sendChannelImageMessage({src, file, width, height}) {
    const channel = this.selectedChannel;
    const channelId = channel?.id;
    const businessId = channel?.business?.id;
    if (!channelId || !businessId) {
      return
    }
    const name = profileStore.t2bUser.fullName;
    const uid = profileStore.t2bUser.id;
    const data: any = {
      text: file.name,
      sender: {uid, name},
      photoUrl: channel?.imageUrl || null,
      image: {
        name: file.name,
        size: {width, height},
        url: src
      },
      type: channelMessageType.IMAGE,
      createdDate: Timestamp.now()
    };
    try {
      const messageRef = await addDoc(businessChannelMessages(businessId, channelId), data);
      const filePath = `/businesses/${businessId}/channels/${channelId}/channelMessages/${messageRef.id}/${file.name}`;
      const fileSnapshot = await uploadBytes(ref(storage, filePath), file);
      const url = await getDownloadURL(fileSnapshot.ref);
      await updateDoc(messageRef,
        'image.url', url,
        'image.storageUri', fileSnapshot.metadata.fullPath
      );
    } catch (e) {
      console.error('failed to send message to channel.', e)
    }
  }

  @Action
  public async sendChannelLocationMessage() {
    const channel = this.selectedChannel;
    const channelId = channel?.id;
    const businessId = channel?.business?.id;
    if (!channelId || !businessId) {
      return
    }
    const name = profileStore.t2bUser.fullName;
    const uid = profileStore.t2bUser.id;
    const getters = this.context.rootGetters
    const data: any = {
      text: getters.selectedLocationAddress || 'Location',
      geopoint: new GeoPoint(getters.selectedLocation.lat, getters.selectedLocation.lng),
      sender: {uid, name},
      photoUrl: channel?.imageUrl || null,
      type: channelMessageType.LOCATION,
      createdDate: Timestamp.now()
    };
    try {
      await addDoc(businessChannelMessages(businessId, channelId), data);
    } catch (e) {
      console.error('failed to send message to channel.', e)
    }
  }

  @Action
  public async sendChannelContactMessage(contact: any) {
    const channel = this.selectedChannel;
    const channelId = channel?.id;
    const businessId = channel?.business?.id;
    if (!channelId || !businessId) {
      return
    }
    const name = profileStore.t2bUser.fullName;
    const uid = profileStore.t2bUser.id;
    const data: any = {
      associateContact: {
        business: contact.business || null,
        email: contact.email,
        id: contact.id,
        name: contact.name,
        phoneNumber: contact.phoneNumber || null,
        photoUrl: contact.photoUrl || null,
        position: contact.position || null,
        type: contact.type
      },
      photoUrl: channel?.imageUrl || null,
      sender: {name, uid},
      text: 'Business Card',
      type: channelMessageType.CONTACT,
      createdDate: Timestamp.now()
    }
    try {
      // todo: ChannelMessagesRepository.save(businessId, channelId, data)
      await addDoc(businessChannelMessages(businessId, channelId), data);
    } catch (e) {
      console.error('failed to send message to channel.', e)
    }
  }

  @Action
  public async shareChannel() {
    const id = this.selectedChannel?.id
    if (!id) {
      return null;
    }
    const axiosResponse = await axios.post('/shortLinks', {
      dynamicLinkInfo: {
        domainUriPrefix: `https://${dynamicLinkConfigCustomer.actionCodeSettings.dynamicLinkDomain}/share/channel`,
        link: `${dynamicLinkConfigCustomer.actionCodeSettings.url}/chats/channels/${id}`,
        androidInfo: {
          androidPackageName: dynamicLinkConfigCustomer.actionCodeSettings.android!!.packageName
        },
        iosInfo: {
          iosBundleId: dynamicLinkConfigCustomer.actionCodeSettings.iOS!!.bundleId
        }
      },
      suffix: {
        option: 'SHORT'
      }
    }, {
      baseURL: 'https://firebasedynamiclinks.googleapis.com/v1',
      params: {key: appConfig.apiKey},
      headers: {
        ['Content-Type']: 'application/json'
      },
      validateStatus: (status) => status === 200
    });
    return axiosResponse.data.shortLink
  }

  @Mutation
  private cacheMessages({channelId, messages}) {
    this._cachedChannelMessages[channelId] = messages
  }

  @Mutation
  private setMessages(messages: any[]) {
    this._channelMessages = messages
  }

  @Mutation
  public setMessagesLoaded(value: boolean) {
    this._channelMessagesLoaded = value
  }

  @Mutation
  public resetChannelState() {
    Object.keys(defaultState).forEach((key) => {
      this['_' + key] = clone(defaultState)[key]
    })
  }

  @Action
  public async loadChannelMessages(channelId: string) {
    const businessId = applicationStore.businessId
    if (!businessId) {
      return null
    }
    const docSnapshot = await getDoc(businessChannel(businessId, channelId));
    if (!docSnapshot.exists) {
      return null
    }
    if (!this._cachedChannelMessages[channelId]) {
      this.cacheMessages({channelId, messages: []})
    }
    this.setMessages(this._cachedChannelMessages[channelId])

    let lastDate: number = 0
    // commit('setLoadingMessages', true);
    // commit('setMessagesLoaded', false);
    this.setMessagesLoaded(false)
    let messagesQuery = query(
      businessChannelMessages(businessId, channelId),
      orderBy('createdDate')
    )
    // if (!full && chat.cases > 1) {
    //     query = query.startAfter(openedDate)
    // }
    if (this._channelMessages.length) {
      const createdDate = this._channelMessages[this._channelMessages.length - 1].data.createdDate
      messagesQuery = query(
          businessChannelMessages(businessId, channelId),
          orderBy('createdDate'),
          startAfter(createdDate)
      )
    }
    return onSnapshot(messagesQuery,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const data = change.doc.data();
          data.id = change.doc.id;
          const message: any = {
            id: change.doc.id,
            data,
            selected: false,
            timestamp: false
          };

          const date: any = message.data.createdDate?.toDate()
          const dayOfMonth = date?.getDate();

          switch (change.type) {
            case 'added': {
              if (this._channelMessages.findIndex((item) => item.id === message.id) === -1) {
                if (!!dayOfMonth && lastDate !== dayOfMonth) {
                  lastDate = dayOfMonth
                  message.timestamp = true
                }
                this._channelMessages.push(message);
              }
              break;
            }
            case 'modified': {
              const modifiedIndex = this._channelMessages.findIndex((item) => item.id === message.id);
              if (modifiedIndex !== -1) {
                if (!!dayOfMonth && lastDate !== dayOfMonth) {
                  lastDate = dayOfMonth
                  message.timestamp = true
                }
                this._channelMessages.splice(modifiedIndex, 1, message);
              }
              break;
            }
            case 'removed': {
              const removedIndex = this._channelMessages.findIndex((item) => item.id === message.id);
              if (removedIndex !== -1) {
                this._channelMessages.splice(removedIndex, 1);
              }
              break;
            }
            default:
          }
        });
        this.setMessagesLoaded(true)
        // commit('setMessagesLoaded', true);
        // commit('setLoadingMessages', false);
        // dispatch('updateSeenByMessages', chatId)
      });
  }

  @Action
  public async deleteMessages(messagesIDs?: string[]) {
    if (!messagesIDs) {
      return
    }
    const channel = this.selectedChannel;
    const channelId = channel?.id;
    const businessId = channel?.business?.id;
    if (!channelId || !businessId) {
      return
    }
    if (messagesIDs.length > 1) {
      const batch = writeBatch(firestore)
      for (const messagesID of messagesIDs) {
        batch.delete(businessChannelMessage(businessId, channelId, messagesID))
      }
      await batch.commit()
    } else {
      await deleteDoc(businessChannelMessage(businessId, channelId, messagesIDs[0]))
    }
  }

  @Action
  public async subscribeChannel(contacts?: any[]) {

    const businessId = applicationStore.businessId;
    const channelId = this.selectedChannel?.id;

    if (!businessId || !channelId || !contacts) {
      return null
    }
    this.setLoading(true)

    try {
      for(const contact of contacts){
        const memberId = contact.id;
        
        //member id
        const contactRef = businessChannel(businessId!, channelId);        
        await updateDoc(contactRef, 'memberIDs', arrayUnion(memberId)); 

        //member contact
        const batch = writeBatch(firestore)
        batch.set(businessChannelMember(businessId, channelId, memberId), contact)
        await batch.commit()               
      }

      this.setLoading(false)
      return true
    } catch (err) {
      console.error('failed to subscribeChannel')
      console.error(err)
      this.setLoading(false)
      return false
    }
  }

  @Action
  public async unSubscribeChannel(memberId: string) {

    const businessId = applicationStore.businessId;
    const channelId = this.selectedChannel?.id;
    if (!businessId || !channelId || !memberId) {
      return null
    }

    try {
      this.setLoading(true)

      //member id
      const contactRef = businessChannel(businessId!, channelId);
      await updateDoc(contactRef, 'memberIDs', arrayRemove(memberId));

      //member contact
      await deleteDoc(businessChannelMember(businessId!, channelId, memberId))
      this.setLoading(false) 
      return true
    } catch (err) {
      console.error('failed to unSubscribeChannel')
      console.error(err)
      this.setLoading(false)
      return false
    }    
  }

  @Action
  public clearState() {
    this.resetChannelState()
  }
}

export const channelsStore = getModule(ChannelsStore);

















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {
  ACTION_MESSAGE_DOWNLOAD,
  ACTION_MESSAGE_COPY,
  ACTION_MESSAGE_DELETE,
  ACTION_MESSAGE_EDIT,
  ACTION_MESSAGE_FORWARD,
  ACTION_MESSAGE_MORE,
  ACTION_MESSAGE_PIN_NOTE,
  ACTION_MESSAGE_REPLY
} from '@/common/context-menu-actions';
import constants from '@/common/constants';
import {twoChars} from '@/utils/helpers';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import {applicationStore} from '@/store/modules/application';
import {Getter} from 'vuex-class';
import PopupMenu from '@/components/PopupMenu.vue';
import type {Timestamp} from 'firebase/firestore';
import type {MessageUI} from '@/domain/model/types';

@Component({
  name: 'base-message',
  components: {PopupMenu, AvatarWithStatus}
})
export default class BaseMessage extends Vue {
  @Prop({default: true}) withTimeIcon?: boolean;
  @Prop({default: false}) right?: boolean;
  @Prop() showAvatar?: boolean;
  @Prop() message?: MessageUI;
  @Prop() userId?: string;
  @Prop() centered?: boolean;
  @Prop({
    default: function() {
      return [false, false]
    }
  }) kind!: boolean[];
  @Prop({default: false}) moreOption!: boolean;
  @Prop({default: true}) withMenu!: boolean;
  @Prop({default: false}) channel!: boolean;

  @Getter selectedTextSession;

  // @Prop() incomeMessage?: HTMLAudioElement;

  cardStyles: any = {
    base: {
      borderRadius: '16px'
    },
    l_sm: {
      borderRadius: '16px 16px 16px 3px'
    },
    l_m: {
      borderRadius: '3px 16px 16px 3px'
    },
    l_em: {
      borderRadius: '3px 16px 16px 16px'
    },
    r_sm: {
      borderRadius: '16px 16px 3px 16px'
    },
    r_m: {
      borderRadius: '16px 3px 3px 16px'
    },
    r_em: {
      borderRadius: '16px 3px 16px 16px'
    }
  }

  margins: any = {
    base: 'my-2',
    l_sm: 'mt-2 mb-1',
    l_m: 'my-1',
    l_em: 'mt-1 mb-2',
    r_sm: 'mt-2 mb-1',
    r_m: 'my-1',
    r_em: 'mt-1 mb-2'
  }

  get messageData() {
    return this.message?.data
  }

  get showName() {
    return !!this.message?.showName
  }

  get styleKind() {
    return this.message?.kind || 'base'
  }

  get senderLabel() {
    return this.messageData && twoChars(this.messageData.sender.name);
  }

  get photoUrl() {
    return this.messageData?.photoUrl
  }

  get edited() {
    return this.messageData?.edited;
  }

  get showRight() {
    return this.right || this.messageData?.sender?.uid === this.userId;
  }

  get timestamp() {
    return this.message?.createdAsTimestamp
  }

  get isShow() {
    if (this.channel && this.withMenu) {
      return true
    } else {
      return this.withMenu && this.isChatMember && !this.systemMessage;
    }
  }

  get isAdmin() {
    return applicationStore.isAdmin;
  }

  get isCurrentUserSender() {
    return this.userId === this.messageData?.sender?.uid;
  }

  get rejected() {
    return this.selectedTextSession?.case?.status === 3
  }

  get actions() {
    const {messageData, userId, rejected, selectedTextSession, pinnedMessage} = this
    if (this.channel) {
      return [
        {
          title: 'Download',
          type: ACTION_MESSAGE_DOWNLOAD,
          class: 'regular-option',
          skip: messageData?.type !== 2 // 2 - message type - image
        },
        {
          title: 'Edit',
          type: ACTION_MESSAGE_EDIT,
          skip: messageData?.type !== 1, // 1 - message type - text
          class: 'regular-option'
        },
        {title: 'Copy', type: ACTION_MESSAGE_COPY, class: 'regular-option'},
        {title: 'Select', type: ACTION_MESSAGE_MORE, class: 'regular-option'},
        {title: 'Delete', type: ACTION_MESSAGE_DELETE, class: 'delete-option', alert: true}
      ]
    } else {
      return [
        {
          title: 'Download',
          type: ACTION_MESSAGE_DOWNLOAD,
          class: 'regular-option',
          skip: messageData?.type !== 14 // 14 - message type - image
        },
        {
          title: pinnedMessage ? 'Unpin note' : 'Pin note',
          type: ACTION_MESSAGE_PIN_NOTE,
          skip: selectedTextSession.type === 3,
          class: 'regular-option'
        },
        {
          title: 'Reply',
          type: ACTION_MESSAGE_REPLY,
          skip: rejected,
          class: 'regular-option'
        },
        {
          title: 'Edit',
          type: ACTION_MESSAGE_EDIT,
          skip: rejected || messageData?.type === 10 || userId !== messageData?.sender?.uid, // 10 - message type - forwarded
          class: 'regular-option'
        },
        {title: 'Copy', type: ACTION_MESSAGE_COPY, class: 'regular-option'},
        {title: 'Forward', type: ACTION_MESSAGE_FORWARD, class: 'regular-option'},
        {title: 'Select', type: ACTION_MESSAGE_MORE, class: 'regular-option'},
        {title: 'Delete', type: ACTION_MESSAGE_DELETE, class: 'delete-option', alert: true}
      ];
    }
  };

  get pinnedMessage() {
    return this.messageData?.pinned && Object.keys(this.messageData.pinned).includes(this.userId!);
  }

  get seen() {
    return this.messageData?.seenBy;
  }

  get isSMS() {
    return this.messageData?.isSMS;
  }

  get platform() {
    return this.messageData?.platform;
  }

  get color() {

    let clsAdmin = '#FEF6E2';
    let clsCustomer = 'primary'; //dark blue
    
    // sms
    if(this.isSMS){

      if(this.showRight){
        clsCustomer = 'chat-type-sms'; //send
      }else{
        clsAdmin = '#DAF5E5'; //recevied
      }

    }else{
      
        // mobile customer app
        if(!this.showRight && (!this.platform || this.platform === 'ios' || this.platform === 'android')){
          clsAdmin = '#F1F5FE'; //light blue
        }
    }   
    
    return this.showRight ? clsCustomer : clsAdmin;
  }

  get timestampIcon() {
    if(this.isSMS){
      return this.seen ? 'seen_white.webp' : 'sent_white.webp';
    }else{
      return this.seen ? 'seen.webp' : 'sent.webp';
    }    
  }

  get timestampColor() {
    return this.showRight ? '#C3D5FC' : 'black';
  }

  get timestampStyle() {
    return {
      fontSize: '10px',
    };
  }

  get showTimestampIcon() {
    return this.withTimeIcon && this.messageData?.sender?.uid === this.userId;
  }

  get systemMessage() {
    return this.messageData?.sender?.type === 3;
  }

  get showTimestamp() {
    return !this.systemMessage
  }

  get messageText() {
    return this.messageData?.text?.wrapLinks();
  }

  get cardStyle() {
    return this.cardStyles[this.styleKind]
  }

  get messageClass() {
    if (this.messageData) {
      if ([
        constants.MESSAGE_TYPE_ADD_PERSON_TO_GROUP,
        constants.MESSAGE_TYPE_APPOINTMENT_BEGIN,
        constants.MESSAGE_TYPE_APPOINTMENT_REQUEST,
        constants.MESSAGE_TYPE_CLOSE_CASE,
        constants.MESSAGE_TYPE_REQUEST_PERSONAL_DATA,
        constants.MESSAGE_TYPE_PERSONA_DATA_RESULT,
        constants.MESSAGE_TYPE_AWAY_CHOICE
      ].includes(this.messageData.type)) {
        return 'chat-system-message'
      }
    }
    return '';
  }

  get paddingClass() {
    return 'px-4 py-2'//'pl-4 pr-12 pt-2 pb-2';
  }

  get marginClass() {
    return this.margins[this.styleKind]
  }

  get textColorClass() {
    return this.showRight ? 'white--text' : '';
  }

  get textStyle() {
    return {
      fontSize: '16px',
      lineHeight: '19px',
      color: this.showRight ? '#ffffff' : '#000000'
    };
  }

  get textPositionClass() {
    return this.showRight ? 'text-xs-right' : 'text-xs-left';
  }

  get justify() {
    return this.centered || this.systemMessage ? 'center' : (this.showRight ? 'end' : 'start');
  }

  get isChatMember() {
    return !!this.selectedTextSession?.memberIDs?.includes(this.userId)
  }

  onMenuItemClicked(item: any) {
    this.$emit('option-item-clicked', item, this.message)
  }

  created() {
    // chatStore.updateMessageSeenBy({id: this.message.id, ...this.messageData})
    // if (!this.isCurrentUserSender) {
    //     await (this.$refs.incomeMessage as any).play();
    // }
  }
}
